<template>
    <section id="Contractservice" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we do</h2>
          <p style="padding-top:20px">Our HR recruiters work passionately to provide our clients with the best talent they are on the lookout for. We collect valid information, Match with the right business and ensure that our hard work definitely pays off for the greater good of both the employee and the employer.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-4 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="text-align:center;">{{ obj.header }}</h4>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Best-talent.jpg',
        header: 'Finding the best talent',
           },
      {
        path: 'services/Employer-experience.jpg',
        header: 'Employer experience',
        },
      {
        path: 'services/Employee-experience.jpg',
        header: 'Employee experience',
        }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Contractservice {
    padding: 50px 30px 10px 30px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}


</style>