<template>
    <Header/>
    <Contractheader/>
    <Contractservice/>
    <ContractSection/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Contractservice from '@/components/Contractservice.vue'
import ContractSection from '@/components/ContractSection.vue'
import Contractheader from '@/components/Contractheader.vue'

export default {
  components: {
     Header,
    Footer,
    Contractservice,
    ContractSection,
    Contractheader
  }
}
</script>